import * as React from "react"
import Founder from "../components/Founder"
import Demo from "../components/Demo"
import Problems from "../components/Problems"
import Customers from "../components/Customers"
import RoadMap from "../components/RoadMap"
import Structure from "../components/Structure"
import Retool from "../components/Retool"
import Solve from "../components/Solve"
import Head from "../components/Head"
import FAQ from "../components/FAQ"
import ToolsFeature from "../components/ToolsFeature"
import UserCases from "../components/UserCases"
import * as style from './bp.story.css'
// import 'mvp.css'
// import 'charts.css'

const pageStyle = {
  // color: "#232129",
  // paddingBottom: '300px',
  backgroundColor: "rgb(244, 243, 251)"
  // fontFamily: "-apple-system, Roboto, sans-serif, serif",
}
const logoStyles = {
  width: "100px"
}
const purpleBgStyle = {
  backgroundColor: 'rgb(244, 243, 251)',
  color: "black",
  padding: '24px 24px 8px', 
  fontWeight: 'bold'
}

// markup
const IndexPage = () => {
  return (
    <main style={pageStyle} id="story">
      <title>Business Plan</title>
      <div className="p-3 ">
        
      <header style={{'padding': '2rem 0'}}> <h1>商业计划书</h1> <img src="http://nbimgs.breadtrip.com/8UkomLvO8GUbeU219CpPklShaGXhmXnK/logo.png" style={logoStyles}/> <p className="mt-5"> <small>updated at 2022-03-16</small> </p> </header>
      
      </div>

      <div className="p-3 ">

      <hr></hr>
      <header> <h1>我们的产品</h1></header>
      <h5>BOARD9是什么</h5>
      <p>
            BOARD9是一款帮助企业以10倍以上速度搭建内部工具的产品。

      </p>
      {/* <ul>
          <li>
            </li>
          <li>国内有近809万的工程师是我们的潜在客户<a href="https://www.miit.gov.cn/gxsj/tjfx/rjy/art/2022/art_7953d1abafe14f00a1b24e693ef73baa.html">[来源：工信部报告]</a></li>
      </ul> */}

      <h5 className="mt-5">我们的长期愿景</h5>
      <p>我们致力于为工程师提供最好的效率产品，提升企业生产效率。</p>
      
      </div>
      
      {/* <div className="p-3 ">
      <Structure></Structure>
      </div> */}
      

      {/* <div className="p-3 ">
      <ToolsFeature></ToolsFeature>
      </div>      */}
      
      <div className="p-3">
      
      <hr></hr>
      <header> <h1>关于内部工具</h1></header>
      

      <div style={purpleBgStyle}>
        <strong className="red">内部工具的重要性</strong>
        <p>内部工具是连接员工和数据的通道，就像汽车的方向盘，直接影响企业的市场表现。</p>      
      </div>
      
        <h5 className="mt-5">企业面临的问题：</h5>
        <p>随着规模的成长，旧的工具不再有效率。</p>
        <p>市场的需求不断变化，产品和内部工具必须迭代。</p>
        <p>同业的竞争，导致企业对内部工具的要求越来越高，越来越急迫。</p>
        

      <h5 className="mt-5">大量、多变、紧急的需求<br></br>VS<br></br>有限的工程师资源</h5>
      
      <p className="mt-5">你可以在一个成长的公司中随处听到这样的声音：</p>
      <h5 className="mt-5">
      “再等等”<br></br>“项目正在排期”<br></br>”有更高的优先级“<br></br>“人手不够，得再招人”<br></br>等等等等...
        </h5>
      

      <p className="mt-5"><strong className="red">想想您熟悉的那些快速发展的企业们，他们一定也饱受困扰</strong></p>
      
      </div>     
      
      <div className="p-3 ">
      <hr></hr>
      <header> <h1>我们如何解决？</h1>  </header>

      <h5>BOARD9就是生产企业内部工具的<strong className="red">“极速工厂”</strong>：</h5>
      <p>✅ 内置常用UI、组件，低代码方式高效完成。</p>
      <p>✅ 多数据源连接，多数据库、saas、api支持。</p>
      <p>✅ 高拓展性，支撑企业业务的从0-100。</p>
      
      
      <h5 className="mt-5">我们还很关注企业的数据安全：</h5>
      <p>✅ 为数据安全而设计，支持从SAAS到VPC内部署到混合云的部署方式，为企业提供全面的安全保障。</p>
      <p>✅ 内置企业级权限管理，保障数据在企业内部的安全流转。</p>
      
      
      <p className="mt-5">BOARD9系统层级示意图：</p>
      <figure> <img src="http://nbimgs.breadtrip.com/OpCLwvvPv0ud1FAt5b8gmrb73vGoxGJT/BOARD9%E6%9E%B6%E6%9E%84.png" alt="Funding" /> </figure>    
      </div>     
      

      <div className="p-3">
      <Customers></Customers>
      </div>     

      {/* <div className="p-3">
      <UserCases></UserCases>
      </div>      */}
        
      {/* <div className="p-3 ">
      <Retool></Retool>
      </div>      */}

      <div className="p-3 ">
      <Founder></Founder>
        
      </div>     
      <div className="p-3">
      <header> <h1>融资需要</h1> <p>本轮计划出让10%～20%的股权，融资100～200万美金。</p> </header>
      <figure> <img src="http://nbimgs.breadtrip.com/ERERuMvi0ufHC1zpoG123zmjiHiopGIA/funding.jpeg" alt="Funding" /> </figure>
        
      </div>     
      
      <div className="p-3 ">
      <RoadMap></RoadMap>        
      </div>     
      
      {/* <div className="p-3 ">
      <FAQ></FAQ>        
      </div>      */}


      <div className="p-3">

      <a href="http://localhost:3000/">Check Demo List</a>
        
      </div>     
      <div className="p-3">

      <Demo></Demo>
        
      </div>     
      <div className="p-3">

      <header> <h1>Thanks</h1> </header>
        
      </div>     

      
      

      


      
      
    </main>
  )
}

export default IndexPage
